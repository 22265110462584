import React from "react"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "./../../components/ServiceLanding/CTA/ServiceCTA"
import HeroHeadless from "./../../components/ServiceSinglePage/Hero/Headless/HeroHeadless"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"

const heroTextTitle = "Headless e-Commerce"
const heroTextSmallText =
    "e-Commerce is one of the fastest growing industries in the world, with consumer behaviour evolving and the explosion of mobile commerce. If you want to build and develop a successful e-Commerce solution, you need the right technology to back it. We’ve got you covered…"
const ctaTitle = "Enter headless commerce."
const heroData = [
    "Bringing in the power of the Jamstack serving blazing fast static pages, but with the ability to deliver dynamic content using technology such as edge functions to give your users a dynamic page experience when they’re shopping online is a breakthrough of headless e-Commerce.",
    "The beauty of using a headless tech stack is to not restrict you or your team. Allow your brand/product to deliver omnichannel digital experiences and transform your sales funnel—backed by the speed, power, and scalability of jamstack technology.",
    "Headless commerce separates your backend infrastructure from your frontend customer touchpoints, so you get more control over your buyer and developer experiences.",
    "Stand out from the crowd, it’s time to push the limits with what you can offer your customers, headless e-Commerce will give you an unfair advantage and set you apart from your competitors."
]
const iconBlockTitle = "Discover the future of e-Commerce"
const icons = [
    {
        name: "performance",
        title: "Blazing fast speed",
        text: "Simply the best way to improve Core Web Vitals, Lighthouse scores, and website conversions? You guessed it—by switching over to Jamstack. "
    },
    {
        name: "content",
        title: "Static, but Dynamic",
        text: "Serve pre-built markup and assets over a CDN, but with the ability to serve dynamic content over edge functions."
    },
    {
        name: "arising",
        title: "Speed equals Sales",
        text: "Having faster page speeds means higher conversion rates, improved SEO, reduced cart abandon rate, and an overall more seamless user experience."
    },
    {
        name: "bolt",
        title: "Faster time to market",
        text: "For marketing teams, building and launching campaigns & content quickly is vital to being successful. This is easily achieved with a decoupled frontend, without breaking your backend."
    },
    {
        name: "computer",
        title: "Ease of Integrations",
        text: "A decoupled frontend means you are not restricted to one single tech stack allowing you to deliver omnichannel experiences across various devices & platforms."
    }
]
const cta1 = {
    title: "Ready to grow your e-Commerce Website?",
    description:
        "Decoupled commerce provides the flexibility to adapt and scale on demand. Deliver highly-integrated, high-availability commerce experiences that drive transactions."
}
const cta2 = {
    title: "Take your e-Commerce website to the future of the web today. Talk to us to find out how."
}

const Headless = ({ location }) => {
    const context = {
        pageName: "Service | Headless Ecommerce",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroHeadless
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="headless"
                    src="../../components/ServiceSinglePage/images/headless-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} />
            </div>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.headlessFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default Headless
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why choose Saigon Digital for headless ecommerce solutions?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "- Expertise in Jamstack: Saigon Digital’s proficiency with Jamstack technology enhances headless ecommerce solutions, leveraging its benefits for your project.\n- Focus on Speed and Performance: Saigon Digital’s emphasis on the performance advantages of headless ecommerce can lead to better user experiences and potentially increased sales.\n- Experience with Decoupled Systems: With a focus on decoupled architecture, Saigon Digital is well-versed in building and managing headless ecommerce systems effectively."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is headless ecommerce?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Headless ecommerce refers to a setup where the frontend (the customer-facing part of the website) is decoupled from the backend (the system managing data and business logic). This headless architecture allows for independent operation of both parts, meaning changes to one do not impact the other, offering greater flexibility and customisation in delivering the user experience."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What are the advantages of headless ecommerce over traditional ecommerce?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Headless ecommerce provides enhanced scalability, as updates to the frontend can occur without disrupting backend functionalities. This means businesses can scale their headless ecommerce site seamlessly while maintaining stable core commerce operations, unlike traditional systems where frontend and backend are tightly integrated."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What are the benefits of adopting a headless ecommerce solution?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Headless ecommerce solutions provide several advantages, such as:\n- Flexibility: Custom build and design the frontend for a distinct user experience.\n- Scalability: Easily scale and modify different components independently.\n- Omnichannel Capability: Provide consistent experiences across web, mobile, and IoT platforms.\n- Improved Performance: Achieve faster load times and enhanced performance through optimised frontend solutions."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Is headless ecommerce suitable for small businesses?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Headless ecommerce can be a great fit for businesses of any size. While it provides advanced features and flexibility, it might require a higher initial investment compared to traditional ecommerce solutions. Small businesses should assess their needs and resources to determine if this headless ecommerce strategy aligns with their goals."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How does headless ecommerce affect SEO?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "A headless ecommerce approach can positively influence SEO by enabling more tailored and optimised frontend solutions. To maximise SEO benefits, ensure that your frontend adheres to best practices, such as fast loading speeds, mobile responsiveness, and well-structured content."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Can I integrate headless ecommerce with my existing CMS?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Yes, you can integrate headless ecommerce with various Content Management Systems (CMS) to create a smooth content delivery experience. Notable CMS options include Contentful, Strapi, and Saigon Digital's partner, Sanity."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title={
                "Headless e-Commerce | Headless e-Commerce Development Agency | Saigon Digital"
            }
            description={
                "Enter headless commerce, bringing in the power of the Jamstack serving blazing fast static pages"
            }
        />
    </>
)
